import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
    Switch, FormControlLabel, FormGroup, Button, CircularProgress,
} from '@material-ui/core';
import useStyles from './styles';

const TestViaCargo = ({ permissions }) => {
    const [loading, setLoading] = useState(false);
    const [smokeTestMode, setSmokeTestMode] = useState(false);
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleViaCargoTest = () => {
        setLoading(true);
        dataProvider.viaCargoTest('shipping', {
            mode: smokeTestMode,
        })
            .then(() => {
                notify(`Test ${smokeTestMode ? 'individual aleatorio' : 'masivo'} en progreso. El resultado será comunicado via email.`);
            })
            .catch((error) => {
                notify(error.body ? error.body.error : 'Error al ejecutar test');
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={(
                    <Switch
                        color="primary"
                        checked={smokeTestMode}
                        onClick={() => setSmokeTestMode(!smokeTestMode)}
                        disabled={loading || permissions !== 'admin'}
                    />
                )}
                label="Smoke Test Mode"
            />
            <Button
                onClick={handleViaCargoTest}
                disabled={loading || permissions !== 'admin'}
                className={classes.button}
            >
                {loading ? (<CircularProgress size="1.5rem" style={{ color: '#FFFFFF' }} />) : 'Ejecutar Test'}
            </Button>
        </FormGroup>
    );
};

export default TestViaCargo;
