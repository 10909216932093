import * as React from 'react';
import {
    BooleanField,
    NumberField,
    Datagrid,
    EditButton,
    List,
    SelectField,
    ShowButton,
    TextField,
} from 'react-admin';
import { Card, CardContent, Grid } from '@material-ui/core';
import choices from './choices';
import ProductFilter from './ProductFilter';
import StockField from './StockField';
import SyncProducts from './SyncProducts';

export default (props) => (
    <>
        <List filters={<ProductFilter />} bulkActionButtons={false} {...props}>
            <Datagrid>
                <SelectField choices={choices.product_types} source="type" />
                <TextField source="name" />
                <TextField source="code" />
                <StockField source="stock" />
                <NumberField source="salesQuantity" label="Cantidad de ventas" />
                <NumberField source="price.special.USD" label="Precio USD" />
                <NumberField source="price.special.ARS" label="Precio ARS" />
                <BooleanField source="published" />
                <BooleanField
                    source="pricing.hasCustomPricing"
                    label="Has custom pricing"
                />
                <EditButton basePath="/products" />
                <ShowButton basePath="/products" />
            </Datagrid>
        </List>
        <Grid item md={12}>
            <Card>
                <CardContent>
                    <SyncProducts />
                </CardContent>
            </Card>
        </Grid>
    </>
);
