// This is a copy of our filesInjection as 2024-12-19 but in JS.

const isUploadedFile = (p) => p?.rawFile instanceof File;
const isObject = (p) => typeof p === 'object' && p !== null && !Array.isArray(p);
const isDate = (p) => p instanceof Date;
const isFile = (p) => isUploadedFile(p);

const convertFileToBase64 = ({ rawFile, ...rest }) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve({ base64: reader.result, ...rest });
    reader.onerror = reject;

    reader.readAsDataURL(rawFile);
});

const convertFilesToBase64 = (p) => {
    if (isFile(p)) return convertFileToBase64(p);
    if (Array.isArray(p)) return Promise.all(p.map(convertFilesToBase64));
    if (isDate(p)) return Promise.resolve(p);
    if (isObject(p)) {
        const entries = Object.entries(p);
        return Promise.all(entries.map(
            ([, value]) => convertFilesToBase64(value),
        )).then((convertedEntries) => Object.fromEntries(entries.map(([key], index) => [
            key, convertedEntries[index],
        ])));
    }
    return Promise.resolve(p);
};

const injectFilesInPayload = (resource, params) => convertFilesToBase64(params)
    .then((convertedParams) => ({
        ...params, ...(convertedParams),
    }));
export default injectFilesInPayload;
