import * as React from 'react';
import {
    useDataProvider,
    useNotify,
} from 'react-admin';
import {
    Button,
    Typography,
} from '@material-ui/core';
import useStyles from '../../dashboard/styles';

const SyncProducts = () => {
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const notify = useNotify();

    const handleClick = () => {
        dataProvider.sync('sync', {})
            .then(() => notify('Sincronización en progreso'))
            .catch((error) => notify(error.body ? error.body.error : 'Error al ejecutar la sincronizacion'));
    };
    return (
        <>
            <Typography variant="h6">Sincronizar</Typography>
            <Button variant="contained" color="primary" onClick={handleClick}>
                Sincronizar productos
            </Button>
        </>

    );
};

export default SyncProducts;
