import {
    CaseFanSlotWidths,
    CpuBrands,
    MotherFormFactors,
    FormFactors,
    ProductTypes,
    RamStickTypes,
    CoolerTypes,
    PowerFormats,
    ConfigIds,
    StorageProtocols,
    StorageConnections,
    PowerCertification80PlusOptions,
    PowerCablingOptions,
    LocationPowerSupply,
    NoLighting,
} from '../../helpers/types';

export const mechanicalKeyboardType = 'mechanical';

export default {
    product_types: [
        { id: ProductTypes.MOTHER, name: 'Mother' },
        { id: ProductTypes.CPU, name: 'CPU' },
        { id: ProductTypes.CPU_COOLER, name: 'CPU Cooler' },
        { id: ProductTypes.RAM, name: 'RAM' },
        { id: ProductTypes.GPU, name: 'GPU' },
        { id: ProductTypes.STORAGE, name: 'Storage' },
        { id: ProductTypes.CASE, name: 'Case' },
        { id: ProductTypes.CASE_FAN, name: 'Case fan' },
        { id: ProductTypes.POWER, name: 'Power' },
        { id: ProductTypes.MONITOR, name: 'Monitor' },
        { id: ProductTypes.MOUSE, name: 'Mouse' },
        { id: ProductTypes.KEYBOARD, name: 'Keyboard' },
        { id: ProductTypes.HEADSET, name: 'Headset' },
        { id: ProductTypes.MICROPHONE, name: 'Microphones' },
        { id: ProductTypes.CHAIR, name: 'Chair' },
        { id: ProductTypes.ACCESSORIES, name: 'Accessories' },
        { id: ProductTypes.SPEAKERS, name: 'Speakers' },
        { id: ProductTypes.THERMAL_PASTE, name: 'Thermal Paste' },
        { id: ProductTypes.FURNITURE, name: 'Furniture' },
        { id: ProductTypes.OPERATIVE_SYSTEM, name: 'Operative System' },
        { id: ProductTypes.OTHER, name: 'Other' },
        { id: ProductTypes.BUILD, name: 'PC Gamer' },
        { id: ProductTypes.NOTEBOOKS, name: 'Notebooks' },
    ],
    ram: {
        type: [
            { id: 'dimm', name: 'DIMM' },
            { id: 'sodimm', name: 'SODIMM' },
        ],
        capacity: [
            { id: '4gb', name: '4GB' },
            { id: '8gb', name: '8GB' },
            { id: '16gb', name: '16GB' },
            { id: '24gb', name: '24GB' },
            { id: '32gb', name: '32GB' },
            { id: '64gb', name: '64GB' },
            { id: '128gb', name: '128GB' },
        ],
        stick_type: [
            { id: RamStickTypes.DDR4, name: 'DDR4' },
            { id: RamStickTypes.DDR5, name: 'DDR5' },
        ],
        clock_frequency_unit: [
            { id: 'mhz', name: 'MHz' },
            { id: 'mts', name: 'MT/s' },
        ],
    },
    mother: {
        form_factor: [
            { id: MotherFormFactors.EATX, name: 'E-ATX' },
            { id: MotherFormFactors.ATX, name: 'ATX' },
            { id: MotherFormFactors.MATX, name: 'M-ATX' },
            { id: MotherFormFactors.ITX, name: 'ITX' },
        ],
    },
    power: {
        cabling: [
            { id: PowerCablingOptions.FIJO, name: 'Fijo' },
            { id: PowerCablingOptions.MODULAR, name: 'Modular' },
        ],
        certification80Plus: [
            { id: PowerCertification80PlusOptions.BRONZE, name: 'Bronze' },
            { id: PowerCertification80PlusOptions.GOLD, name: 'Gold' },
            { id: PowerCertification80PlusOptions.PLATINUM, name: 'Platinum' },
            { id: PowerCertification80PlusOptions.TITANIUM, name: 'Titanium' },
        ],
        format: [
            { id: PowerFormats.ATX, name: 'ATX' },
            { id: PowerFormats.SFX, name: 'SFX' },
        ],
    },
    form_factor: [
        { id: FormFactors.FULL_TOWER, name: 'Full-Tower' },
        { id: FormFactors.MID_TOWER, name: 'Mid-Tower' },
        { id: FormFactors.MINI_TOWER, name: 'Mini-Tower' },
    ],
    radiator_width: [
        { id: '240', name: '240' },
        { id: '360', name: '360' },
    ],
    warranty: [
        { id: 'por 1 mes', name: '1 mes' },
        { id: 'por 3 meses', name: '3 meses' },
        { id: 'por 6 meses', name: '6 meses' },
        { id: 'por 1 año', name: '1 año' },
        { id: 'por 2 años', name: '2 años' },
        { id: 'por 3 años', name: '3 años' },
        { id: 'de por vida', name: 'De por vida' },
    ],
    cpu_brands: [
        { id: CpuBrands.AMD, name: 'AMD' },
        { id: CpuBrands.INTEL, name: 'Intel' },
    ],
    brands: [
        { id: 'Adata', name: 'Adata' },
        { id: 'AMD', name: 'AMD' },
        { id: 'Antec', name: 'Antec' },
        { id: 'Aoc', name: 'Aoc' },
        { id: 'APC', name: 'APC' },
        { id: 'Arctic cooling', name: 'Arctic cooling' },
        { id: 'Asrock', name: 'Asrock' },
        { id: 'Astro', name: 'Astro' },
        { id: 'Asus', name: 'Asus' },
        { id: 'Azza', name: 'Azza' },
        { id: 'Benq', name: 'Benq' },
        { id: 'Biostar', name: 'Biostar' },
        { id: 'Coolermaster', name: 'Coolermaster' },
        { id: 'Corsair', name: 'Corsair' },
        { id: 'Creative', name: 'Creative' },
        { id: 'Dell', name: 'Dell' },
        { id: 'Ducky', name: 'Ducky' },
        { id: 'Edifier', name: 'Edifier' },
        { id: 'Elgato', name: 'Elgato' },
        { id: 'EPIC', name: 'EPIC' },
        { id: 'EVGA', name: 'EVGA' },
        { id: 'Gainward', name: 'Gainward' },
        { id: 'Genius', name: 'Genius' },
        { id: 'Gigabyte', name: 'Gigabyte' },
        { id: 'Glorious', name: 'Glorious' },
        { id: 'Gskill', name: 'Gskill' },
        { id: 'Hikvision', name: 'Hikvision' },
        { id: 'HP', name: 'HP' },
        { id: 'Hyperx', name: 'Hyperx' },
        { id: 'Intel', name: 'Intel' },
        { id: 'JBL', name: 'JBL' },
        { id: 'Kingston', name: 'Kingston' },
        { id: 'Lexar', name: 'Lexar' },
        { id: 'LG', name: 'LG' },
        { id: 'Lian Li', name: 'Lian Li' },
        { id: 'Logitech', name: 'Logitech' },
        { id: 'Mad Catz', name: 'Mad Catz' },
        { id: 'Markvision', name: 'Markvision' },
        { id: 'Microsoft', name: 'Microsoft' },
        { id: 'MSI', name: 'MSI' },
        { id: 'Noctua', name: 'Noctua' },
        { id: 'NSX', name: 'NSX' },
        { id: 'Nvidia', name: 'Nvidia' },
        { id: 'NZXT', name: 'NZXT' },
        { id: 'Patriot', name: 'Patriot' },
        { id: 'Plantronics', name: 'Plantronics' },
        { id: 'PNY', name: 'PNY' },
        { id: 'PowerColor', name: 'PowerColor' },
        { id: 'Qbox', name: 'Qbox' },
        { id: 'Razer', name: 'Razer' },
        { id: 'Samsung', name: 'Samsung' },
        { id: 'SCUF', name: 'SCUF' },
        { id: 'Seagate', name: 'Seagate' },
        { id: 'Sennheiser', name: 'Sennheiser' },
        { id: 'Sentey', name: 'Sentey' },
        { id: 'SilverStone', name: 'SilverStone' },
        { id: 'Steam', name: 'Steam' },
        { id: 'Steelseries', name: 'Steelseries' },
        { id: 'Thermalright', name: 'Thermalright' },
        { id: 'ThermalTake', name: 'ThermalTake' },
        { id: 'Thrustmaster', name: 'Thrustmaster' },
        { id: 'Toshiba', name: 'Toshiba' },
        { id: 'TP-Link', name: 'TP-Link' },
        { id: 'Western Digital', name: 'Western Digital' },
        { id: 'XFX', name: 'XFX' },
        { id: 'Zotac', name: 'Zotac' },
    ],
    cpu_cooler: {
        case_radiator_width: [
            { id: CaseFanSlotWidths.W_120, name: '120' },
            { id: CaseFanSlotWidths.W_140, name: '140' },
            { id: CaseFanSlotWidths.W_240, name: '240' },
            { id: CaseFanSlotWidths.W_280, name: '280' },
            { id: CaseFanSlotWidths.W_360, name: '360' },
            { id: CaseFanSlotWidths.W_420, name: '420' },
        ],
        type: [
            { id: CoolerTypes.WATER_COOLER, name: 'Water Cooler' },
            { id: CoolerTypes.AIR_COOLER, name: 'Air Cooler' },
        ],
        fan_sizes: [
            { id: '92mm', name: '92mm' },
            { id: '120mm', name: '120mm' },
            { id: '140mm', name: '140mm' },
            { id: 'dual_tower', name: 'DUAL TOWER' },
        ],
        lighting_options: [
            { id: 'argb', name: 'ARGB' },
            { id: 'rgb', name: 'RGB' },
            { id: 'none', name: 'Sin iluminación' },
        ],
    },
    storage: {
        types: [
            { id: 'internal', name: 'Internal' },
            { id: 'external', name: 'External' },
        ],
        technologies: [
            { id: 'HDD', name: 'HDD' },
            { id: 'SSD', name: 'SSD' },
        ],
        connections: [
            { id: StorageConnections.M2, name: 'M2' },
            { id: StorageConnections.SATA, name: 'SATA' },
            { id: StorageConnections.USB, name: 'USB' },
        ],
        just_sata_protocol: [
            { id: StorageProtocols.SATA3, name: 'SATA 3' },
        ],
        protocols: [
            { id: StorageProtocols.SATA3, name: 'SATA 3' },
            { id: StorageProtocols.NVME, name: 'NVMe' },
        ],
    },
    case_fan: {
        width: [
            { id: CaseFanSlotWidths.W_120, name: '120' },
            { id: CaseFanSlotWidths.W_140, name: '140' },
        ],
        connection: [
            { id: '3-pin', name: '3-Pin' },
            { id: '4-pin', name: '4-Pin' },
        ],
        lighting: [
            { id: 'argb', name: 'ARGB' },
            { id: 'rgb', name: 'RGB' },
            { id: 'fixedColor', name: 'Color fijo' },
            { id: 'none', name: 'Sin iluminación' },
        ],
    },
    iva: [
        { id: 21, name: '21%' },
        { id: 10.5, name: '10.5%' },
    ],
    pricing: [
        { id: ConfigIds.INTEREST_RATE, name: ConfigIds.INTEREST_RATE },
        { id: ConfigIds.PRICING_CMP, name: ConfigIds.PRICING_CMP },
        { id: ConfigIds.PRICING_COM_BANCARIA, name: ConfigIds.PRICING_COM_BANCARIA },
        {
            id: ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA,
            name: ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA,
        },
        { id: ConfigIds.PRICING_G_ADM, name: ConfigIds.PRICING_G_ADM },
        { id: ConfigIds.PRICING_IIBB, name: ConfigIds.PRICING_IIBB },
        { id: ConfigIds.PRICING_MARKUP, name: ConfigIds.PRICING_MARKUP },
    ],
    monitor: {
        resolutions: [
            { id: 'fullHd', name: 'Full HD' },
            { id: '2k', name: '2K' },
            { id: '4k', name: '4K' },
        ],
        refreshRates: [
            { id: '60hz', name: '60 Hz' },
            { id: '75hz', name: '75 Hz' },
            { id: '120hz', name: '120 Hz' },
            { id: '144hz', name: '144 Hz' },
            { id: '165hz', name: '165 Hz' },
            { id: '240hz', name: '240 Hz' },
            { id: '300hz', name: '300 Hz' },
            { id: '360hz', name: '360 Hz' },
        ],
        responseTimes: [
            { id: '1ms', name: '1 ms' },
            { id: '5ms', name: '5 ms' },
        ],
        panelTypes: [
            { id: 'ips', name: 'IPS' },
            { id: 'tn', name: 'TN' },
            { id: 'va', name: 'VA' },
        ],
        vesaOptions: [
            { id: '100x100', name: '100 x 100' },
            { id: '75x75', name: '75 x 75' },
        ],
    },
    cpu: {
        model: [
            { id: 'i3', name: 'Core I3' },
            { id: 'i5', name: 'Core I5' },
            { id: '5Ultra', name: 'Core 5 ULTRA' },
            { id: 'i7', name: 'Core I7' },
            { id: '7Ultra', name: 'Core 7 ULTRA' },
            { id: 'i9', name: 'Core I9' },
            { id: 'athlon', name: 'Athlon' },
            { id: 'ryzen3', name: 'Ryzen 3' },
            { id: 'ryzen5', name: 'Ryzen 5' },
            { id: 'ryzen7', name: 'Ryzen 7' },
            { id: 'ryzen9', name: 'Ryzen 9' },
        ],
        socket: [
            { id: 'AM4', name: 'AMD AM4' },
            { id: 'AM5', name: 'AMD AM5' },
            { id: 'TRX4', name: 'AMD TRX4' },
            { id: '1151', name: 'Intel 1151' },
            { id: '1200', name: 'Intel 1200' },
            { id: '1700', name: 'Intel 1700' },
            { id: '1851', name: 'Intel 1851' },
            { id: '2011', name: 'Intel 2011' },
        ],
    },
    gpu: {
        chips: [
            { id: 'amd', name: 'AMD' },
            { id: 'nvidia', name: 'Nvidia' },
        ],
    },
    mouse: {
        connectivity: [
            { id: 'wired', name: 'Cableado' },
            { id: 'wireless', name: 'Inalámbrico' },
            { id: 'hybrid', name: 'Híbrido' },
        ],
        lighting: [
            { id: 'argb', name: 'ARGB' },
            { id: 'rgb', name: 'RGB' },
            { id: 'fixedColor', name: 'Color fijo' },
            { id: 'noLighting', name: 'Sin iluminación' },
        ],
    },
    keyboardTypes: [
        { id: mechanicalKeyboardType, name: 'Mecánico' },
        { id: 'membrane', name: 'Membrana' },
        { id: 'hybrid', name: 'Hibrido' },
    ],
    keyboardSwitches: [
        { id: 'red', name: 'Red' },
        { id: 'brown', name: 'Brown' },
        { id: 'blue', name: 'Blue' },
        { id: 'black', name: 'Black' },
        { id: 'green', name: 'Green' },
        { id: 'silver', name: 'Silver' },
    ],
    keyboardBacklights: [
        { id: 'argb', name: 'ARGB' },
        { id: 'rgb', name: 'RGB' },
        { id: 'fixedColor', name: 'Color Fijo' },
        { id: 'noBacklight', name: 'Sin retroiluminación' },
    ],
    keyboardConnectivity: [
        { id: 'wired', name: 'Cableado' },
        { id: 'wireless', name: 'Inalámbrico' },
    ],
    keyboardSize: [
        { id: 'fullSize', name: 'Full Size' },
        { id: 'tkl', name: 'TKL' },
        { id: '60', name: 'Compacto 60%' },
        { id: '65', name: 'Compacto 65%' },
        { id: '70', name: 'Compacto 70%' },
        { id: 'others', name: 'Otros' },
    ],
    headset: {
        connectivity: [
            { id: 'wired', name: 'Cableado' },
            { id: 'wireless', name: 'Inalámbrico' },
        ],
        connectionType: [
            { id: 'bluetooth', name: 'Bluetooth' },
            { id: 'jack', name: 'Jack 3.5mm' },
            { id: 'usb', name: 'USB' },
        ],
        lighting: [
            { id: 'argb', name: 'ARGB' },
            { id: 'rgb', name: 'RGB' },
            { id: 'fixedColor', name: 'Color Fijo' },
            { id: 'noBacklight', name: 'Sin retroiluminación' },
        ],
        sound: [
            { id: '5.1', name: '5.1' },
            { id: '7.1', name: '7.1' },
            { id: '2.0', name: '2.0' },
        ],
        format: [
            { id: 'headset', name: 'Headset' },
            { id: 'in-ear', name: 'In-ear' },
        ],
    },
    chair: {
        material: [
            { id: 'syntheticLeather', name: 'Cuero Sintético' },
            { id: 'fabric', name: 'Tela' },
        ],
        maximumSupportedWeight: [
            { id: '100kg', name: '100 kg' },
            { id: '120kg', name: '120 kg' },
            { id: '150kg', name: '150 kg' },
        ],
    },
    case: {
        powerSupplySlotLocation: [
            { id: LocationPowerSupply.TOP, name: 'Superior' },
            { id: LocationPowerSupply.BOTTOM, name: 'Inferior' },
        ],
        lighting: [
            { id: 'rgb', name: 'RGB' },
            { id: 'argb', name: 'ARGB' },
            { id: NoLighting, name: 'No incluye' },
        ],
        formFactor: [
            { id: FormFactors.FULL_TOWER, name: 'Full-Tower' },
            { id: FormFactors.MID_TOWER, name: 'Mid-Tower' },
            { id: FormFactors.MINI_TOWER, name: 'Mini-Tower' },
        ],
    },
};
