import * as React from 'react';
import {
    Datagrid, EditButton,
    List, NumberField, ShowButton,
    TextField, UrlField,
} from 'react-admin';

import SlideFilter from './SlideFilter';

export default (props) => (
    <List {...props} filters={<SlideFilter />} bulkActionButtons={false} sort={{ field: 'order', order: 'ASC' }}>
        <Datagrid>
            <NumberField source="order" />
            <TextField source="title" />
            <UrlField source="link" target="_blank" rel="noopener noreferrer" />
            <EditButton basePath="/slides" />
            <ShowButton basePath="/slides" />
        </Datagrid>
    </List>
);
