import jsonExport from 'jsonexport/dist';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { downloadCSV } from 'ra-core';
import choices from './choices';

dayjs.extend(utc);
dayjs.extend(timezone);

const forceAsString = (field) => field && `="${field}"`;

const ORDER_FIELD_HEADER_MAP = {
    orderCode: 'Código de la orden',
    createdAt: 'Fecha de creación',
    status: 'Estado',
    billId: 'Número de factura',
    totalPrice: 'Total cobrado',
    invoiceType: 'Tipo de factura',
    paymentMethod: 'Método de pago',
    shippingDataDescription: 'Descripción de envío',
    shippingPrice: 'Precio del envío',
    shippingIva: '% de IVA envío',
    appliedCouponDiscountPercentage: '% de descuento de cupón',
    buyerDataBusinessName: 'Razón social',
    buyerDataCuit: 'CUIT',
    buyerDataIvaCondition: 'Condición frente al IVA',
    buyerDataFirstName: 'Nombre',
    buyerDataLastName: 'Apellido',
    buyerDataDni: 'DNI',
    buyerDataPhone: 'Teléfono',
    shippingDataPhone: 'Teléfono (del envío)',
    paymentDataAmount: 'Monto del pago',
    payerEmail: 'E-Mail de quien paga por MP',
    buyerDataPostalCode: 'Código Postal del comprador',
    buyerDataLocality: 'Localidad del comprador',
    buyerDataProvince: 'Provincia del comprador',
    appliedCouponCode: 'Código de cupón',
    trackingNumber: 'Número de guía',
    priceType: 'Tipo de precio usado',
    productsPrice: 'Subtotal',
    paymentGatewayInterests: 'Intereses MP',
    dolarValue: 'Valor del dolar al momento de la venta',
    globalIngresosBrutos: 'Ingresos brutos (global)',
    globalBankCommission: 'Comisión bancaria (global)',
    globalAdminExpenses: 'Gastos administrativos (global)',
    globalPricingMarkup: 'Markup (global)',
    globalInterestRate: 'Tasa de interés GF (global)',
    globalMPCommission: 'Comisión MP (global)',
    globalBankCommissionList: 'Precio de lista comisión bancaria (global)',
};

const PRODUCT_FIELD_HEADER_MAP = {
    productType: 'Tipo de Producto',
    productName: 'Nombre del Producto',
    productCode: 'Código del Producto',
    productGtin: 'GTIN',
    productPricingCost: 'Costo del producto (USD)',
    unitPrice: 'Precio unitario cobrado',
    productIva: '% IVA',
    productQuantity: 'Cantidad',
    productSerialNumber: 'Número de serie',
    customIngresosBrutos: 'Ingresos brutos (custom)',
    customBankCommission: 'Comisión bancaria (custom)',
    customAdminExpenses: 'Gastos administrativos (custom)',
    customPricingMarkup: 'Markup (custom)',
    customMPCommission: 'Comisión MP (custom)',
    customInterestRate: 'Tasa de interés (custom)',
    customBankCommissionList: 'Precio de lista comisión bancaria (custom)',
};

const getOrderInformation = (order) => ({
    [ORDER_FIELD_HEADER_MAP.orderCode]: order.code,
    [ORDER_FIELD_HEADER_MAP.createdAt]: dayjs.utc(order.createdAt).tz('America/Argentina/Buenos_Aires')
        .format('DD/MM/YYYY HH:mm:ss'),
    [ORDER_FIELD_HEADER_MAP.status]: choices.status
        .find((elem) => elem.id === order.status)?.name || order.status,
    [ORDER_FIELD_HEADER_MAP.billId]: order.billId,
    [ORDER_FIELD_HEADER_MAP.totalPrice]: order.totalPrice,
    [ORDER_FIELD_HEADER_MAP.invoiceType]: choices.invoicesTypes
        .find((elem) => elem.id === order.buyerData.invoiceType)?.name
        || order.buyerData.invoiceType,
    [ORDER_FIELD_HEADER_MAP.paymentMethod]: choices.paymentMethods
        .find((elem) => elem.id === order.paymentData.method)?.name
    || order.paymentData.method,
    [ORDER_FIELD_HEADER_MAP.shippingDataDescription]: order.shippingData.description,
    [ORDER_FIELD_HEADER_MAP.shippingPrice]: order.paymentData.shippingPrice,
    [ORDER_FIELD_HEADER_MAP.shippingIva]: order.shippingData.iva,
    [ORDER_FIELD_HEADER_MAP.appliedCouponDiscountPercentage]: order
        .appliedCoupon?.discount_percentage,
    [ORDER_FIELD_HEADER_MAP.buyerDataBusinessName]: order.buyerData.businessName,
    [ORDER_FIELD_HEADER_MAP.buyerDataCuit]: order.buyerData.cuit,
    [ORDER_FIELD_HEADER_MAP.buyerDataIvaCondition]: choices.ivaConditions
        .find((elem) => elem.id === order.buyerData.ivaCondition)?.name
    || order.buyerData.ivaCondition,
    [ORDER_FIELD_HEADER_MAP.buyerDataFirstName]: order.buyerData.firstName,
    [ORDER_FIELD_HEADER_MAP.buyerDataLastName]: order.buyerData.lastName,
    [ORDER_FIELD_HEADER_MAP.buyerDataDni]: order.buyerData.dni,
    [ORDER_FIELD_HEADER_MAP.buyerDataPhone]: forceAsString(order.buyerData.phone),
    [ORDER_FIELD_HEADER_MAP.shippingDataPhone]: forceAsString(order.shippingData.phone),
    [ORDER_FIELD_HEADER_MAP.paymentDataAmount]: order.paymentData.amount,
    [ORDER_FIELD_HEADER_MAP.payerEmail]: order.paymentData.mpResponse?.response?.payer?.email,
    [ORDER_FIELD_HEADER_MAP.buyerDataPostalCode]: order.buyerData.codigoPostal,
    [ORDER_FIELD_HEADER_MAP.buyerDataLocality]: order.buyerData.locality,
    [ORDER_FIELD_HEADER_MAP.buyerDataProvince]: order.buyerData.province,
    [ORDER_FIELD_HEADER_MAP.appliedCouponCode]: order.appliedCoupon?.code,
    [ORDER_FIELD_HEADER_MAP.trackingNumber]: forceAsString(order.trackingNumber),
    [ORDER_FIELD_HEADER_MAP.priceType]: order.priceType,
    [ORDER_FIELD_HEADER_MAP.productsPrice]: order.productsPrice,
    [ORDER_FIELD_HEADER_MAP.paymentGatewayInterests]: order.paymentGatewayInterests,
    [ORDER_FIELD_HEADER_MAP.dolarValue]: order.exchangeRate?.exchangeRate,
    [ORDER_FIELD_HEADER_MAP.globalIngresosBrutos]: order.globalPricingParameters?.PRICING_IIBB,
    [ORDER_FIELD_HEADER_MAP.globalBankCommission]: order
        .globalPricingParameters?.PRICING_COM_BANCARIA,
    [ORDER_FIELD_HEADER_MAP.globalAdminExpenses]: order.globalPricingParameters?.PRICING_G_ADM,
    [ORDER_FIELD_HEADER_MAP.globalPricingMarkup]: order.globalPricingParameters?.PRICING_MARKUP,
    [ORDER_FIELD_HEADER_MAP.globalInterestRate]: order.globalPricingParameters?.INTEREST_RATE,
    [ORDER_FIELD_HEADER_MAP.globalMPCommission]: order.globalPricingParameters?.PRICING_CMP,
    [ORDER_FIELD_HEADER_MAP.globalBankCommissionList]: order
        .globalPricingParameters?.PRICING_COM_BANCARIA_PRECIO_LISTA,
});

const getOrderProductInformation = (orderProduct, product) => ({
    [PRODUCT_FIELD_HEADER_MAP.productType]: orderProduct.item.productShadow.type,
    [PRODUCT_FIELD_HEADER_MAP.productName]: orderProduct.item.productShadow.name,
    [PRODUCT_FIELD_HEADER_MAP.productCode]: orderProduct.item.productShadow.code,
    [PRODUCT_FIELD_HEADER_MAP.productGtin]: forceAsString(product?.gtin),
    [PRODUCT_FIELD_HEADER_MAP.productPricingCost]: orderProduct.item
        .productShadow.pricing?.cost,
    [PRODUCT_FIELD_HEADER_MAP.unitPrice]: orderProduct.item.chargedPrice,
    [PRODUCT_FIELD_HEADER_MAP.productIva]: orderProduct.item.productShadow.pricing.iva,
    [PRODUCT_FIELD_HEADER_MAP.productQuantity]: orderProduct.item.quantity,
    [PRODUCT_FIELD_HEADER_MAP.productSerialNumber]: forceAsString(orderProduct.serialNumber),
    [PRODUCT_FIELD_HEADER_MAP.customIngresosBrutos]: orderProduct.item.productShadow
        .pricing?.customPricing?.PRICING_IIBB,
    [PRODUCT_FIELD_HEADER_MAP.customBankCommission]: orderProduct.item.productShadow
        .pricing?.customPricing?.PRICING_COM_BANCARIA,
    [PRODUCT_FIELD_HEADER_MAP.customAdminExpenses]: orderProduct.item.productShadow
        .pricing?.customPricing?.PRICING_G_ADM,
    [PRODUCT_FIELD_HEADER_MAP.customPricingMarkup]: orderProduct.item.productShadow
        .pricing?.customPricing?.PRICING_MARKUP,
    [PRODUCT_FIELD_HEADER_MAP.customMPCommission]: orderProduct.item.productShadow
        .pricing?.customPricing?.PRICING_CMP,
    [PRODUCT_FIELD_HEADER_MAP.customInterestRate]: orderProduct.item.productShadow
        .pricing?.customPricing?.INTEREST_RATE,
    [PRODUCT_FIELD_HEADER_MAP.customBankCommissionList]: orderProduct.item.productShadow
        .pricing?.customPricing?.PRICING_COM_BANCARIA_PRECIO_LISTA,
});

const headers = [
    ORDER_FIELD_HEADER_MAP.orderCode,
    ORDER_FIELD_HEADER_MAP.createdAt,
    ORDER_FIELD_HEADER_MAP.status,
    ORDER_FIELD_HEADER_MAP.billId,
    ORDER_FIELD_HEADER_MAP.totalPrice,
    ORDER_FIELD_HEADER_MAP.invoiceType,
    ORDER_FIELD_HEADER_MAP.paymentMethod,
    ORDER_FIELD_HEADER_MAP.shippingDataDescription,
    ORDER_FIELD_HEADER_MAP.shippingPrice,
    ORDER_FIELD_HEADER_MAP.shippingIva,
    ORDER_FIELD_HEADER_MAP.appliedCouponDiscountPercentage,
    ORDER_FIELD_HEADER_MAP.buyerDataBusinessName,
    ORDER_FIELD_HEADER_MAP.buyerDataCuit,
    ORDER_FIELD_HEADER_MAP.buyerDataIvaCondition,
    ORDER_FIELD_HEADER_MAP.buyerDataFirstName,
    ORDER_FIELD_HEADER_MAP.buyerDataLastName,
    ORDER_FIELD_HEADER_MAP.buyerDataDni,
    ORDER_FIELD_HEADER_MAP.buyerDataPhone,
    ORDER_FIELD_HEADER_MAP.shippingDataPhone,
    ORDER_FIELD_HEADER_MAP.paymentDataAmount,
    ORDER_FIELD_HEADER_MAP.payerEmail,
    ORDER_FIELD_HEADER_MAP.buyerDataPostalCode,
    ORDER_FIELD_HEADER_MAP.buyerDataLocality,
    ORDER_FIELD_HEADER_MAP.buyerDataProvince,
    ORDER_FIELD_HEADER_MAP.appliedCouponCode,
    ORDER_FIELD_HEADER_MAP.trackingNumber,
    ORDER_FIELD_HEADER_MAP.priceType,
    ORDER_FIELD_HEADER_MAP.productsPrice,
    ORDER_FIELD_HEADER_MAP.paymentGatewayInterests,
    ORDER_FIELD_HEADER_MAP.dolarValue,
    PRODUCT_FIELD_HEADER_MAP.productType,
    PRODUCT_FIELD_HEADER_MAP.productName,
    PRODUCT_FIELD_HEADER_MAP.productCode,
    PRODUCT_FIELD_HEADER_MAP.productGtin,
    PRODUCT_FIELD_HEADER_MAP.productPricingCost,
    PRODUCT_FIELD_HEADER_MAP.unitPrice,
    PRODUCT_FIELD_HEADER_MAP.productIva,
    PRODUCT_FIELD_HEADER_MAP.productQuantity,
    PRODUCT_FIELD_HEADER_MAP.productSerialNumber,
    PRODUCT_FIELD_HEADER_MAP.customIngresosBrutos,
    PRODUCT_FIELD_HEADER_MAP.customBankCommission,
    PRODUCT_FIELD_HEADER_MAP.customAdminExpenses,
    PRODUCT_FIELD_HEADER_MAP.customPricingMarkup,
    PRODUCT_FIELD_HEADER_MAP.customMPCommission,
    PRODUCT_FIELD_HEADER_MAP.customInterestRate,
    PRODUCT_FIELD_HEADER_MAP.customBankCommissionList,
    ORDER_FIELD_HEADER_MAP.globalIngresosBrutos,
    ORDER_FIELD_HEADER_MAP.globalBankCommission,
    ORDER_FIELD_HEADER_MAP.globalAdminExpenses,
    ORDER_FIELD_HEADER_MAP.globalPricingMarkup,
    ORDER_FIELD_HEADER_MAP.globalInterestRate,
    ORDER_FIELD_HEADER_MAP.globalMPCommission,
    ORDER_FIELD_HEADER_MAP.globalBankCommissionList,
];

const BATCH_SIZE = 70;

const fetchInBatches = async (items, fetchFunction, type, resource) => {
    const batches = [];
    for (let i = 0; i < items.length; i += BATCH_SIZE) {
        const batch = items.slice(i, i + BATCH_SIZE);
        batches.push(fetchFunction(batch, type, resource));
    }
    return Promise.all(batches).then((results) => results.flat());
};

const orderExporter = async (orders, fetchRelatedRecords) => {
    const orderProducts = orders.flatMap((order) => order.products)
        .map((orderProduct) => orderProduct.item);

    const products = await fetchInBatches(orderProducts, fetchRelatedRecords, 'product', 'products');

    const ordersForExport = orders.flatMap((order) => {
        const orderInformation = getOrderInformation(order);
        return order.products.map((orderProduct) => ({
            ...orderInformation,
            ...getOrderProductInformation(
                orderProduct, products[orderProduct.item.product],
            ),
        }));
    });
    jsonExport(ordersForExport, { headers }, (err, csv) => {
        if (err) {
            console.error('Error al exportar las órdenes:', err);
            return;
        }
        const csvWithBOM = `\uFEFF${csv}`;
        downloadCSV(csvWithBOM, 'orders');
    });
};

export default orderExporter;
