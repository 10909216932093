import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    button: {
        maxWidth: '160px',
        background: '#3f51b5',
        marginTop: '16px',
        color: '#fff',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            color: '#3f51b5',
        },
    },
}));

export default useStyles;
