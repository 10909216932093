import * as React from 'react';
import {
    Create, FormTab, TabbedForm, TextInput, ImageInput, ImageField,
    NumberInput,
} from 'react-admin';
import { required } from 'ra-core';

export default (props) => (
    <Create title="Create a Slide" {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <NumberInput source="order" validate={[required()]} />
                <TextInput source="title" validate={[required()]} />
                <TextInput source="link" type="url" validate={[required()]} />
                <ImageInput source="image" validate={[required()]} label="Image (1920x760)">
                    <ImageField source="url" title="title" />
                </ImageInput>
                <ImageInput source="verticalImage" validate={[required()]} label="Vertical Image (1440x2000)">
                    <ImageField source="url" title="title" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Create>
);
