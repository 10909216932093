import React, { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
    SelectInput,
    NumberInput,
    BooleanInput,
    required,
    TextInput,
    FormDataConsumer,
} from 'react-admin';
import { useForm } from 'react-final-form';

import choices from '../choices';
import classes from './styles';
import {
    FormFactors,
    LocationPowerSupply,
    MotherFormFactors,
    NoLighting,
    PowerFormats,
} from '../../../helpers/types';
import { isRecordNew } from './utils';
import { fanData } from '../constants';

const CaseProductSpecsInput = ({ record }) => {
    const form = useForm();
    const recordIsNew = isRecordNew(record);

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <BooleanInput
                    label="Fuente"
                    source="specs.powerSupplyIncluded"
                />
                <FormDataConsumer>
                    {({ formData }) => {
                        if (!formData.specs?.powerSupplyIncluded) {
                            return null;
                        }
                        return (
                            <NumberInput
                                label="Watts fuente"
                                source="specs.includedPowerSupplySupportedWatts"
                                style={classes.textInputStyles}
                            />
                        );
                    }}
                </FormDataConsumer>
                <BooleanInput
                    label="Periféricos"
                    source="specs.peripheralsIncluded"
                    defaultValue={recordIsNew ? false : undefined}
                />
                <SelectInput
                    label="Mother Form factor"
                    source="specs.mother_form_factor"
                    choices={choices.mother.form_factor}
                    validate={[required()]}
                    defaultValue={recordIsNew ? MotherFormFactors.EATX : undefined}
                    style={classes.textInputStyles}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <SelectInput
                    label="Tamaño de fuente"
                    source="specs.size"
                    choices={choices.power.format}
                    validate={[required()]}
                    defaultValue={recordIsNew ? PowerFormats.ATX : undefined}
                    style={classes.textInputStyles}
                />
                <SelectInput
                    label="Ubicación fuente"
                    source="specs.powerSupplySlotLocation"
                    choices={choices.case.powerSupplySlotLocation}
                    defaultValue={recordIsNew ? LocationPowerSupply.TOP : undefined}
                    style={classes.textInputStyles}
                />
                <SelectInput
                    label="Iluminación"
                    source="specs.lighting"
                    choices={choices.case.lighting}
                    defaultValue={recordIsNew ? NoLighting : undefined}
                    style={classes.textInputStyles}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <SelectInput
                    label="Tamaño Gabinete"
                    source="specs.form_factor"
                    choices={choices.case.formFactor}
                    defaultValue={recordIsNew ? FormFactors.MINI_TOWER : undefined}
                    style={classes.textInputStyles}
                />
                <NumberInput
                    label="Width (mm)"
                    source="specs.width"
                    style={classes.textInputStyles}
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    style={classes.textInputStyles}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const selectedSupports = [];

                        if (formData.specs?.compatibilityWithWatercooler120) selectedSupports.push('120mm');
                        if (formData.specs?.compatibilityWithWatercooler140) selectedSupports.push('140mm');
                        if (formData.specs?.compatibilityWithWatercooler240) selectedSupports.push('240mm');
                        if (formData.specs?.compatibilityWithWatercooler280) selectedSupports.push('280mm');
                        if (formData.specs?.compatibilityWithWatercooler360) selectedSupports.push('360mm');
                        if (formData.specs?.compatibilityWithWatercooler420) selectedSupports.push('420mm');

                        const radiatorSupport = selectedSupports.join(', ');
                        form.change('specs.radiator_support', radiatorSupport);

                        return (
                            <TextInput
                                label="Radiator Support"
                                fullWidth
                                source="specs.radiator_support"
                                value={radiatorSupport}
                                style={classes.textInputStyles}
                                {...rest}
                            />
                        );
                    }}
                </FormDataConsumer>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <>
                            <BooleanInput
                                label="Watercooler 120"
                                source="specs.compatibilityWithWatercooler120"
                                defaultValue={
                                    formData.specs?.compatibilityWithWatercooler120 || false
                                }
                            />

                            <BooleanInput
                                label="Watercooler 140"
                                source="specs.compatibilityWithWatercooler140"
                                defaultValue={
                                    formData.specs?.compatibilityWithWatercooler140 || false
                                }
                            />

                            <BooleanInput
                                label="Watercooler 240"
                                source="specs.compatibilityWithWatercooler240"
                                defaultValue={
                                    formData.specs?.compatibilityWithWatercooler240 || false
                                }
                                {...rest}
                                onChange={(isChecked) => {
                                    form.change('specs.compatibilityWithWatercooler240', isChecked);
                                    if (isChecked) {
                                        form.change('specs.compatibilityWithWatercooler120', true);
                                    }
                                }}
                            />
                            <BooleanInput
                                label="Watercooler 280"
                                source="specs.compatibilityWithWatercooler280"
                                defaultValue={
                                    formData.specs?.compatibilityWithWatercooler280 || false
                                }
                                {...rest}
                                onChange={(isChecked) => {
                                    form.change('specs.compatibilityWithWatercooler280', isChecked);
                                    if (isChecked) {
                                        form.change('specs.compatibilityWithWatercooler140', true);
                                    }
                                }}
                            />

                            <BooleanInput
                                label="Watercooler 360"
                                source="specs.compatibilityWithWatercooler360"
                                defaultValue={
                                    formData.specs?.compatibilityWithWatercooler360 || false
                                }
                                {...rest}
                                onChange={(isChecked) => {
                                    form.change('specs.compatibilityWithWatercooler360', isChecked);
                                    if (isChecked) {
                                        form.change('specs.compatibilityWithWatercooler120', true);
                                        form.change('specs.compatibilityWithWatercooler240', true);
                                    }
                                }}
                            />

                            <BooleanInput
                                label="Watercooler 420"
                                source="specs.compatibilityWithWatercooler420"
                                defaultValue={
                                    formData.specs?.compatibilityWithWatercooler420 || false
                                }
                                {...rest}
                                onChange={(isChecked) => {
                                    form.change('specs.compatibilityWithWatercooler420', isChecked);
                                    if (isChecked) {
                                        form.change('specs.compatibilityWithWatercooler140', true);
                                        form.change('specs.compatibilityWithWatercooler280', true);
                                    }
                                }}
                            />
                        </>
                    )}
                </FormDataConsumer>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" width="80%">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">120mm</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">140mm</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">Híbrido 120/140</Typography>
                    </Grid>
                    {fanData.map((row) => (
                        <Fragment key={row.label}>
                            <Grid item xs={3}>
                                <Typography>{row.label}</Typography>
                            </Grid>
                            {row.sources.map((source) => (
                                <Grid item xs={3} key={source} style={source === null ? { backgroundColor: '#ccc' } : {}}>
                                    {source ? (
                                        <NumberInput source={source} label="" record={record} />
                                    ) : null}
                                </Grid>
                            ))}
                        </Fragment>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default CaseProductSpecsInput;
