import * as React from 'react';
import {
    ImageField, NumberField, Show, Tab, TabbedShowLayout, TextField, UrlField,
} from 'react-admin';
import SlideTitle from './SlideTitle';

export default (props) => (
    <Show title={<SlideTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <NumberField source="order" />
                <TextField source="title" />
                <UrlField source="link" target="_blank" rel="noopener noreferrer" />
                <ImageField label="Image (1920x760)" source="image.url" title="image.title" />
                <ImageField label="Verical Image (1440x2000)" source="verticalImage.url" title="image.title" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
