import React from 'react';
import { ArrayInput, SimpleFormIterator, NumberInput } from 'react-admin';

const InstallmentsInterestRate = () => (
    <ArrayInput source="value" label="Cuotas">
        <SimpleFormIterator>
            <NumberInput source="installments" label="Cantidad de cuotas" />
            <NumberInput source="interest" label="Interés (%)" min={0} />
        </SimpleFormIterator>
    </ArrayInput>
);

export default InstallmentsInterestRate;
